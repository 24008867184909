import { z } from 'zod';
import { TenantConfigViewItem, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { Option } from 'src/components/Configure/ConfigureModal';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { get, cloneDeep, isNil } from 'lodash';
import { AppType, FlowStatus } from 'src/services/configuration/codecs/bindings.types';
import {
  FavoriteResponseItem,
  ValidatedAssortmentFavorite,
  ValidatedFavoriteResponse,
} from 'src/components/Subheader/Favorites/Favorites.types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ServiceContainer from 'src/ServiceContainer';
import { ExtraPivotParam, zSubheaderDefnProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';
import { BasicItem } from 'src/worker/pivotWorker.types';
import { Option as DropdownOption } from 'src/components/Subheader/Subheader';
import { AppState } from 'src/store';
import { getValidValues } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { getUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { logError } from 'src/services/loggingService';

export interface SubheaderViewDefns extends z.infer<typeof zSubheaderDefnProps> {}

export interface SubheaderDefns {
  groupBy: DropdownConfigViewData;
  sortBy: DropdownConfigViewData;
  countLimit: DropdownConfigViewData;
  pareDown: DropdownConfigViewData;
}

// FIXME: do we need to include extra props here if only manipulating groupByOptions?
export interface OverLoadSubheaderProps {
  title?: 'Grid View' | 'Quick Snapshot';
  showFlowStatus?: boolean;
  showSearch: true;
  groupByOptions?: DropdownConfigViewData;
}

export interface SubheaderDropdownSlice {
  defaultSelection: number;
  selection: number | null;
  options: TenantConfigViewItem[];
  refreshOnChange?: boolean;
}

export interface DropdownConfigViewData extends TenantConfigViewData {
  hideEmptyRow?: boolean;
  refreshOnChange?: boolean;
}

export interface GroupBySlice extends SubheaderDropdownSlice {}

export interface SortBySlice extends SubheaderDropdownSlice {
  direction: SortByDirection;
}

export interface PareDownSlice {
  defaultSelections: TenantConfigViewItem[];
  selections: TenantConfigViewItem[];
  options: TenantConfigViewItem[];
}

export interface SubheaderSlice {
  title?: string;
  showFlowStatus?: boolean;
  showSearch?: true;
  search: string;
  altSearch: string;
  groupBy: GroupBySlice;
  groupByDefn?: TenantConfigViewData;
  sortBy: SortBySlice;
  sortByDefn?: TenantConfigViewData;
  pareDown: PareDownSlice;
  pareDownDefn?: TenantConfigViewData;
  lookBackPeriod: string;
  countLimit?: number;
  countLimitOptions?: number[];
  countLimitDefault?: number;
  subheaderLoading: boolean;
  configureSelections?: Option[];
  flowStatus: number[];
  flowStatusConfig?: FlowStatus;
  altFlowStatus: number[];
  /** @deprecated use `favorites` instead of `favoritesList` */
  favoritesList?: FavoriteResponseItem[];
  favorites: ValidatedFavoriteResponse[];
  activeFavorite: ValidatedAssortmentFavorite | null;
  possibleBreadcrumbs: string[] | null;
  // topMemberDropdown props
  topMemberOptions?: BasicItem[];
  topMemberSelection?: string | undefined;
  //for snapshots etc
  extraPivotParamsConfig?: Record<string, ExtraPivotParam>;
  extraPivotParamsOptions?: Record<string, DropdownOption[]>;
  extraPivotParamsSelections: Record<string, string | number | null>;
}

const emptyItem = {
  dataIndex: '',
  text: '',
  dimension: 'product',
};

export const initialGroupBy: GroupBySlice = {
  defaultSelection: 0,
  selection: null,
  options: [emptyItem],
};

export const initialSortBy: SortBySlice = {
  defaultSelection: 0,
  selection: null,
  direction: 'desc',
  options: [emptyItem],
};

export const initialPareDown = {
  defaultSelections: [],
  selections: [],
  options: [emptyItem],
};

const initialState: SubheaderSlice = {
  search: '',
  altSearch: '',
  groupBy: initialGroupBy,
  sortBy: initialSortBy,
  pareDown: initialPareDown,
  lookBackPeriod: 'YTD',
  flowStatus: [],
  altFlowStatus: [],
  subheaderLoading: false,
  flowStatusConfig: {} as FlowStatus,
  favoritesList: [],
  favorites: [],
  activeFavorite: null,
  possibleBreadcrumbs: null,
  // topMemberDropdown props
  topMemberOptions: [],
  topMemberSelection: undefined,
  extraPivotParamsConfig: {},
  extraPivotParamsOptions: {},
  extraPivotParamsSelections: {},
};

export const emptySubheaderState = cloneDeep(initialState);

export function _handleDropdownExtras(
  ddSlice: SubheaderDropdownSlice,
  viewDefn: DropdownConfigViewData,
  currentSelection: TenantConfigViewItem | null
) {
  ddSlice.options = viewDefn.view;
  if (viewDefn.hideEmptyRow !== true) {
    ddSlice.options.unshift(emptyItem);
  }
  if (viewDefn.default) {
    const defaultSelection = ddSlice.options.findIndex((opt) => opt.dataIndex === viewDefn.default);
    ddSlice.defaultSelection = defaultSelection;
  }
  let newSelection = -1;
  if (!isNil(currentSelection)) {
    newSelection = ddSlice.options.findIndex((opt) => opt.text === currentSelection.text);
  }
  if (newSelection >= 0) {
    ddSlice.selection = newSelection;
  } else {
    ddSlice.selection = null;
  }
  if (viewDefn.refreshOnChange) {
    ddSlice.refreshOnChange = viewDefn.refreshOnChange;
  }
  return ddSlice;
}

const subheaderSlice = createSlice({
  name: 'subheader',
  initialState: initialState,
  reducers: {
    requestViewDefns: (state, _action: PayloadAction<string[]>) => {
      // this action requires a payload, but it is used in various containers,
      // and isn't meant to go into state
      state.subheaderLoading = true;
    },
    receiveViewDefns: (state, action: PayloadAction<SubheaderDefns>) => {
      const defns = action.payload;
      let groupBy: GroupBySlice = cloneDeep(initialGroupBy);

      const groupByDefn = defns.groupBy;

      if (groupByDefn) {
        const curSel = get(state.groupBy.options, `[${state.groupBy.selection}]`, null);
        groupBy = {
          ...state.groupBy,
          ..._handleDropdownExtras(groupBy, groupByDefn, curSel),
        };
      }
      let sortBy: SortBySlice = { ...cloneDeep(initialSortBy), direction: state.sortBy.direction || 'desc' };
      if (defns.sortBy) {
        const curSel = get(state.sortBy.options, `[${state.sortBy.selection}]`, null);
        const sortByDefn = defns.sortBy;
        sortBy = {
          ...state.sortBy,
          ..._handleDropdownExtras(sortBy, sortByDefn, curSel),
        };
      }

      let pareDown: PareDownSlice = cloneDeep(initialPareDown);

      if (defns.pareDown) {
        const defaultText = (defns.pareDown as any).default || [];
        const pareDownDefn = defns.pareDown;
        const selections = pareDownDefn.view.filter((x) => defaultText.indexOf(x.text) != -1);
        pareDown = {
          ...state.pareDown,
          selections,
          defaultSelections: selections,
          options: pareDownDefn.view,
        };
      }

      let countLimitOptions;
      let countLimit;

      if (defns.countLimit) {
        countLimitOptions = defns.countLimit.options;
        // If there is already a count limit selected and it's available in limit options, keep it
        if (state.countLimit && countLimitOptions && countLimitOptions.includes(state.countLimit)) {
          countLimit = state.countLimit;
        } else {
          countLimit = defns.countLimit.default as number;
        }
      }
      return {
        ...state,
        groupBy,
        sortBy,
        countLimit,
        countLimitOptions,
        countLimitDefault: defns.countLimit ? (defns.countLimit.default as number) : 0,
        subheaderLoading: false,
        groupByDefn: defns.groupBy,
        sortByDefn: defns.sortBy,
        pareDownDefn: defns.pareDown,
        pareDown,
      };
    },
    updateSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateAlternateSearch: (state, action: PayloadAction<string>) => {
      state.altSearch = action.payload;
    },
    updateConfigureSelections: (state, action: PayloadAction<Option[]>) => {
      state.configureSelections = action.payload;
    },
    updateFlowStatus: (state, action: PayloadAction<number[]>) => {
      state.flowStatus = action.payload;
    },
    updateFlowStatusConfig: (state, action: PayloadAction<FlowStatus>) => {
      state.flowStatusConfig = action.payload;
    },
    updateAlternateFlowStatus: (state, action: PayloadAction<number[]>) => {
      state.altFlowStatus = action.payload;
    },
    updateLookBackPeriod: (state, action: PayloadAction<string>) => {
      state.lookBackPeriod = action.payload;
    },
    updateTopMemberSelection: (state, action: PayloadAction<string>) => {
      state.topMemberSelection = action.payload;
    },
    receiveTopMemberDropdownData(state, action: PayloadAction<BasicItem[]>) {
      state.topMemberOptions = action.payload;
      if (!isNil(state.topMemberSelection)) {
        const foundSelection = state.topMemberOptions.find((i) => i.id === state.topMemberSelection);
        if (!foundSelection) state.topMemberSelection = state.topMemberOptions[0].id;
      }
    },
    updateGroupBy: (state, action: PayloadAction<number | null>) => {
      state.groupBy = {
        ...state.groupBy,
        selection: action.payload,
      };
    },
    updateSortBy: (state, action: PayloadAction<number | null>) => {
      state.sortBy = {
        ...state.sortBy,
        selection: action.payload,
      };
    },
    updatePareDown: (state, action: PayloadAction<TenantConfigViewItem[]>) => {
      state.pareDown = {
        ...state.pareDown,
        selections: action.payload,
      };
    },
    updateCountLimit: (state, action: PayloadAction<number>) => {
      state.countLimit = action.payload;
    },
    /** @deprecated see updateFavorites instead */
    updateFavoritesList: (state, action: PayloadAction<FavoriteResponseItem[]>) => {
      state.favoritesList = action.payload;
    },
    updateFavorites: (state, action: PayloadAction<ValidatedFavoriteResponse[]>) => {
      const activeFavorite = action.payload.find((fav) => fav.active);
      state.activeFavorite = isNil(activeFavorite) ? null : activeFavorite.jsonBlob;
      state.favorites = action.payload;
    },
    updateSortByDirection: (state) => {
      if (state.sortBy.direction === 'asc') {
        state.sortBy.direction = 'desc';
      } else {
        state.sortBy.direction = 'asc';
      }
    },
    maybeUpdateSortByDirection: (state, action: PayloadAction<string>) => {
      if (state.sortBy.direction !== action.payload) {
        if (state.sortBy.direction === 'asc') {
          state.sortBy.direction = 'desc';
        } else {
          state.sortBy.direction = 'asc';
        }
      }
    },
    // TODO: The only logic that is needed in this action is the hideEmptyRow logic,
    // everything else (title, boolean values) are not relevant.
    // This should be moved to the receiveViewDefns action handler.
    // For more details see https://s5stratosdev.atlassian.net/browse/INT-1436
    overloadSubheader: (state, action: PayloadAction<OverLoadSubheaderProps>) => {
      const { groupByOptions, ...newProps } = action.payload;

      let options: TenantConfigViewItem[];
      if (!isNil(groupByOptions)) {
        options = groupByOptions?.hideEmptyRow ? [...groupByOptions.view] : [emptyItem, ...groupByOptions.view];
      } else {
        options = [];
      }

      return {
        ...state,
        ...newProps,
        groupBy: {
          ...state.groupBy,
          options,
        },
      };
    },
    receiveError: (state, action: PayloadAction<string>) => {
      // mild side effects here
      ServiceContainer.loggingService.error(`An error occured in the subheader: ${action.payload}`);
      return initialState;
    },
    setPossibleBreadcrumbs: (state, action: PayloadAction<string[]>) => {
      state.possibleBreadcrumbs = action.payload;
    },
    setExtraPivotParamsConfig: (state, action: PayloadAction<Record<string, ExtraPivotParam>>) => {
      state.extraPivotParamsConfig = action.payload;
    },
    setExtraPivotParamsOptions: (state, action: PayloadAction<Record<string, DropdownOption[]>>) => {
      state.extraPivotParamsOptions = {
        ...state.extraPivotParamsOptions,
        ...action.payload,
      };
    },
    clearExtraPivotParamsOptions: (state) => {
      state.extraPivotParamsOptions = {};
    },
    setExtraPivotParamsSelection: (state, action: PayloadAction<{ key: string; value: string | number | null }>) => {
      const { key, value } = action.payload;
      state.extraPivotParamsSelections[key] = value;
    },
    cleanUp: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActivePage, (state) => {
      state.configureSelections = undefined;
    });
  },
});

export const {
  requestViewDefns,
  receiveViewDefns,
  updateSearch,
  updateAlternateSearch,
  updateConfigureSelections,
  updateFlowStatus,
  updateFlowStatusConfig,
  updateAlternateFlowStatus,
  updateLookBackPeriod,
  updateGroupBy,
  updateSortBy,
  updatePareDown,
  updateCountLimit,
  updateFavoritesList,
  updateFavorites,
  updateSortByDirection,
  maybeUpdateSortByDirection,
  overloadSubheader,
  receiveError,
  setPossibleBreadcrumbs,
  setExtraPivotParamsConfig,
  setExtraPivotParamsOptions,
  setExtraPivotParamsSelection,
  clearExtraPivotParamsOptions,
  cleanUp,
  updateTopMemberSelection,
  receiveTopMemberDropdownData,
} = subheaderSlice.actions;

export const fetchExtraPivotParamsOptions = createAsyncThunk<void, void, { state: AppState }>(
  'subheader/fetchExtraPivotParamsOptions',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const { extraPivotParamsConfig, extraPivotParamsSelections } = state.subheader;

    if (!extraPivotParamsConfig) {
      // Safety check
      return;
    }

    type OptionsResult = { key: string; options: DropdownOption[] };

    const promises: Promise<OptionsResult>[] = Object.keys(extraPivotParamsConfig).map(
      async (key): Promise<OptionsResult> => {
        const param = extraPivotParamsConfig[key];
        const dataApi = param.dataApi;

        try {
          if (dataApi.isListData) {
            // Call listData API
            const res = await ServiceContainer.pivotService.listData(dataApi.defnId, AppType.Assortment, {
              ...dataApi.params,
            });
            const options: DropdownOption[] = res.flat.map((item: any) => ({
              ...item,
              text: item.label, // Add 'text' for SubheaderDropdown
            }));
            return { key, options };
          } else {
            // Handle non-listData APIs
            const values = await getValidValues(getUrl(dataApi));
            const options: DropdownOption[] = values.map((v: any) => ({
              value: v.value,
              label: v.label,
              text: v.label, // Add 'text' for SubheaderDropdown
            }));
            return { key, options };
          }
        } catch (error) {
          logError(`Failed to fetch options for ${key}:`, error);
          return { key, options: [] };
        }
      }
    );

    const results = await Promise.all(promises);
    // Build a map of key to options
    const optionsMap: Record<string, DropdownOption[]> = {};
    results.forEach(({ key, options }) => {
      optionsMap[key] = options;
    });

    // Set all the new options all at once
    dispatch(setExtraPivotParamsOptions(optionsMap));

    // Now, update the selections based on the new options, leaving the pre-selected ones intact
    const updatedSelections: Record<string, string | number | null> = { ...extraPivotParamsSelections };

    Object.keys(optionsMap).forEach((key) => {
      const options = optionsMap[key];
      const currentSelectionValue = extraPivotParamsSelections[key];
      let newSelectionValue: string | number | null = null;

      if (options && options.length > 0) {
        const values = options.map((option) => option.value);

        // Check if current selection value exists in new options values
        if (!isNil(currentSelectionValue) && values.includes(currentSelectionValue)) {
          // Current selection value is valid, keep it
          newSelectionValue = currentSelectionValue;
        } else {
          // Default to first valid option's value
          newSelectionValue = values[0] ?? null;
        }
      } else {
        // No options available, set selection to null - this shouldn't get hit, unless the pivot returns empty
        newSelectionValue = null;
      }

      updatedSelections[key] = newSelectionValue;
    });

    // Dispatch updated selections only for keys in optionsMap, thanks stack overflow
    Object.entries(updatedSelections).forEach(([key, value]) => {
      if (optionsMap.hasOwnProperty(key)) {
        dispatch(setExtraPivotParamsSelection({ key, value }));
      }
    });
  }
);

export default subheaderSlice.reducer;
