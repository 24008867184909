import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AnyAction as BaseAction } from 'redux';
import { AppEpic, AppState, AppThunkDispatch } from 'src/store';
import { of } from 'rxjs';
import {
  fetchEnhancedOvertimeChartData,
  fetchEnhancedOvertimeGridData,
  receiveOverTimeConfig,
  requestOverTimeConfig,
  updateAggBys,
} from './EnhancedOvertime.slice';

import {
  inputIsNotNullOrUndefined,
  isScopeDefined,
  isSubheaderLoaded,
  topMemInWorklistSelected,
} from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  EnhancedOvertimeComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { receiveWorklistConfig, updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';
import {
  ExtraPivotOptions,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { isEmpty, isNil, isString } from 'lodash';
import { Option } from '../Configure/ConfigureModal';
import { receiveViewDefns } from '../Subheader/Subheader.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { ConfigureOvertimeConfig } from 'src/pages/AssortmentBuild/OvertimeView/OvertimeView.types';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { TimeChartConfig } from '../TimeChart/TimeChart';
import container from 'src/ServiceContainer';

export function fetchConfigurableGridConfigs(viewDefns: string[]) {
  return async (dispatch: AppThunkDispatch): Promise<BaseAction | void> => {
    try {
      dispatch(requestOverTimeConfig());
      const [chartViewDefn, gridViewDefn] = await container.tenantConfigClient.getTenantViewDefns<
        Array<TimeChartConfig | ConfigureOvertimeConfig>
      >({
        defnIds: viewDefns,
        appName: ASSORTMENT,
      });
      dispatch(
        receiveOverTimeConfig({
          chartViewDefn: (chartViewDefn as unknown) as TimeChartConfig,
          gridViewDefn: (gridViewDefn as unknown) as ConfigureOvertimeConfig,
        })
      );
    } catch (e) {}
  };
}
export const enhancedOvertimeConfigLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType( setActiveSubPage.type, receiveWorklistConfig.type),
    map(() => maybeGetComponentProps<EnhancedOvertimeComponent>(state$.value, ConfDefnComponentType.enhancedOvertime)),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ defns }) => {
      return of(fetchConfigurableGridConfigs(defns.view));
    })
  );
};

export const enhancedOvertimeDataLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      updateSelectedItem.type,
      updateAggBys.type,
      receiveViewDefns.type,
      receiveOverTimeConfig.type
    ),
    map(() => maybeGetComponentProps<EnhancedOvertimeComponent>(state$.value, ConfDefnComponentType.enhancedOvertime)),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns }) => {
      const viewState = state$.value.pages.assortmentBuild.enhancedOvertime;
      const { chartViewDefn } = viewState;
      const [chartModelDefn, gridModelDefn] = defns.models;

      if (isNil(chartViewDefn) || isEmpty(viewState.aggBys)) {
        return of();
      }

      const topMembers = state$.value.worklist.selectedItemId;
      const chartOptions: ExtraPivotOptions = {
        topMembers,
        aggBy: [chartViewDefn?.timeLevel, viewState.aggBys[0]]
          .map((agg: string | Option) => (isString(agg) ? `level:${agg}` : `${agg.type}:${agg.dataIndex}`))
          .join(','),
      };
      const finalChartOptions = organizeListDataOptions(chartOptions);

      const aggBy = [chartViewDefn?.timeLevel, ...viewState.aggBys]
        .map((agg: string | Option) => (isString(agg) ? `level:${agg}` : `${agg.type}:${agg.dataIndex}`))
        .join(',');
      const gridOptions: ExtraPivotOptions = {
        topMembers,
        aggBy,
      };
      const finalGridOptions = organizeListDataOptions(gridOptions);

      return of(
        fetchEnhancedOvertimeChartData(chartModelDefn, finalChartOptions),
        fetchEnhancedOvertimeGridData(gridModelDefn, finalGridOptions)
      );
    })
  );
};
