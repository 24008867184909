import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { setExtraPivotParamsSelection, updateGroupBy } from 'src/components/Subheader/Subheader.slice';
import { fetchGridViewData, receiveGridViewConfig } from './GridView.slice';
import {
  inputIsNotNullOrUndefined,
  isScopeDefined,
  isSubheaderLoaded,
  topMemInWorklistSelected,
} from 'src/utils/Functions/epicsFunctions';
import { isNil } from 'lodash';
import {
  ConfDefnComponentType,
  GridViewComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { getAggBys, ExtraPivotOptions, organizeListDataOptions } from '../StyleColorReview.slice';
import { receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { extraParamsAvailIfNeeded, getExtraPivotParams } from 'src/components/Subheader/Subheader.container';

export const gridViewLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      updateGroupBy.type,
      updateSelectedItem.type,
      receiveGridViewConfig.type,
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      setExtraPivotParamsSelection.type
    ),
    map(() => maybeGetComponentProps<GridViewComponent>(state$.value, ConfDefnComponentType.gridView)),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    filter(() => extraParamsAvailIfNeeded(state$.value)), // filter for extraParams
    mergeMap(({ defns, topMembers }) => {
      const { dataApi, model: modelDefn } = defns;
      const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

      // Retrieve extra pivot params
      const extraPivotParams = getExtraPivotParams(state$.value.subheader);
      const aggBy = getAggBys(state$.value.subheader, dataApi);
      const options: ExtraPivotOptions = {
        ...(!isNil(topMembers) ? { topMembers } : {}),
        aggBy,
        ...extraPivotParams,
      };
      const finalOptions = organizeListDataOptions(options, dataApi);

      return of(fetchGridViewData(finalModelDefn, finalOptions));
    })
  );
};
