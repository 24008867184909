import React, { useCallback } from 'react';
import { Tooltip } from '@material-ui/core';
import { Link, PathMatch, useLocation, matchPath } from 'react-router-dom';
import styles from 'src/components/Sidenav/Sidenav.styles';
import { getUrlWithoutFourthPath } from 'src/pages/NavigationShell/navigationUtils';

interface NavigationItemProps {
  name: string;
  link: string;
  icon: string;
  disabled: boolean;
  qa: string;
  collapsed?: boolean;
  onClick: () => void;
  errorView?: boolean;
  hidden?: boolean;
  tooltip?: string;
}

const NavigationItemContent = ({
  icon,
  name,
  errorView,
  tooltip,
}: Pick<NavigationItemProps, 'icon' | 'name' | 'errorView' | 'collapsed' | 'tooltip'>) => {
  let configuredTooltip = tooltip ?? name;

  if (errorView) {
    configuredTooltip = `${name} is not properly configured`;
  }
  return (
    <Tooltip title={configuredTooltip} placement="right-start" arrow={true}>
      <div className={styles.navItemWrapper(errorView)}>
        <div className="col-1 d-flex flex-column align-items-center">
          {errorView ? <i className="fal fa-exclamation-triangle" /> : <i className={`${icon}`} />}
        </div>
        <div className="col-11" style={styles.viewName}>
          {name}
        </div>
      </div>
    </Tooltip>
  );
};

const MemoizedNavigationItemContent = React.memo(NavigationItemContent);

// While it would be nice to memoize NavigationItem, this cannot happen the way it is currently implemented.
// In order for the active NavigationItem style to properly be applied, the Route component children must be rerendered every time a route change is detected.
export const NavigationItem = ({
  name,
  link,
  icon,
  disabled,
  qa,
  collapsed,
  onClick,
  errorView,
  hidden,
  tooltip,
}: NavigationItemProps) => {
  const onLinkClick = useCallback(() => onClick(), [onClick]);
  const content = (
    <MemoizedNavigationItemContent
      icon={icon}
      name={name}
      errorView={errorView}
      collapsed={collapsed}
      tooltip={tooltip}
    />
  );

  const rendered = (routeMatch: PathMatch | null) =>
    disabled || errorView ? (
      <a className={styles.navLink(routeMatch, disabled)}>{content}</a>
    ) : (
      <Link to={`${link}`} className={styles.navLink(routeMatch)} data-qa={qa} onClick={onLinkClick}>
        {content}
      </Link>
    );

  const location = useLocation();
  const match = matchPath(`${getUrlWithoutFourthPath(link)}/*`, location.pathname);
  return (
    <li
      className="nav-item pb-0 pt-0"
      style={{ width: '-webkit-fill-available', display: hidden ? 'none' : undefined }}
    >
      {rendered(match)}
    </li>
  );
};
