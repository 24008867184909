import { connect } from 'react-redux';
import Subheader from './Subheader';
import { AppState, AppThunkDispatch } from 'src/store';
import {
  cleanUp,
  updateSearch,
  requestViewDefns,
  receiveViewDefns,
  updateGroupBy,
  updateSortBy,
  updateFlowStatus,
  updateSortByDirection,
  updateLookBackPeriod,
  updateCountLimit,
  updateAlternateSearch,
  updateAlternateFlowStatus,
  updatePareDown,
  updateFavoritesList,
  receiveError,
  updateFavorites,
  setExtraPivotParamsOptions,
  setExtraPivotParamsSelection,
  SubheaderSlice,
} from 'src/components/Subheader/Subheader.slice';
import { receiveError as receiveStyleEditError } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.slice';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { FavoriteResponseItem, ValidatedFavoriteResponse } from 'src/components/Subheader/Favorites/Favorites.types';
import { getAssortmentFavoriteByDefnId, getFavoriteByDefnId } from './Favorites/Favorites.client';
import { resetGridModel } from 'src/components/Sidenav/SideNav.slice';
import { setRightContainerPayload } from '../RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from '../RightContainer/RightContainer';
import { SubheaderOwnProps, SubheaderStateProps, SubheaderDispatchProps } from './Subheader.types';
import { Option } from './Subheader';

import { toast } from 'react-toastify';
import { ComponentErrorType } from '../ErrorBoundary/ErrorBoundary.slice';
import { validateFavorites } from 'src/components/Subheader/Favorites/Favorites.actions';
import { getCrumbPathFromActiveCrumb } from 'src/state/breadcrumbs/Breadcrumbs.utils';
import { makeBreadcrumbSensitive } from '../higherOrder/withBreadcrumb';
import { flow, isNil } from 'lodash';

/** Util function to get the extra pivot params for data fetch */
export function getExtraPivotParams(subheaderState: SubheaderSlice): Record<string, string | number> {
  const extraPivotParamsSelections = subheaderState.extraPivotParamsSelections ?? {};
  const extraPivotParamsConfig = subheaderState.extraPivotParamsConfig ?? {};
  const extraPivotParams: Record<string, string | number> = {};

  // We only want to send the params that are configured for the view
  Object.entries(extraPivotParamsSelections).forEach(([key, value]) => {
    if (!isNil(value) && extraPivotParamsConfig.hasOwnProperty(key)) {
      extraPivotParams[key] = value;
    }
  });

  return extraPivotParams;
}

/** Filter function to use in epics, to wait for pivotParams options to be available  */
export function extraParamsAvailIfNeeded(state: AppState): boolean {
  const { extraPivotParamsConfig, extraPivotParamsOptions } = state.subheader;

  // If extraPivotParams is configured in this view
  if (extraPivotParamsConfig && Object.keys(extraPivotParamsConfig).length > 0) {
    // Check if extraPivotParamsOptions have been fetched
    if (!extraPivotParamsOptions || Object.keys(extraPivotParamsOptions).length === 0) {
      // Options have not been fetched yet
      return false;
    }
  }
  // Either extraPivotParamsConfig is not configured, or options have been fetched
  return true;
}

export function mapStateToProps(state: AppState, ownProps: SubheaderOwnProps): SubheaderStateProps {
  const maybeActiveBreadcrumb = state.breadcrumbs.activeBreadcrumb;
  const maybeActiveCrumbPath = maybeActiveBreadcrumb
    ? getCrumbPathFromActiveCrumb(maybeActiveBreadcrumb, state.breadcrumbs.crumbPaths)
    : null;

  return {
    subheader: state.subheader,
    lookBackPeriods: state.appConfig.tenantConfig.lookBackPeriods,
    flowStatusOptions: state.appConfig.tenantConfig.flowStatus,
    showDateRange: true,
    gridFilterModel: state.sidenav.gridFilterModel,
    gridSortModel: state.sidenav.gridSortModel,
    gridColumnState: state.sidenav.gridSortModel,
    activeCrumbPath: maybeActiveCrumbPath,
    activeBreadcrumb: maybeActiveBreadcrumb,
    topMemberSelection: state.subheader.topMemberSelection,
    topMemberOptions: state.subheader.topMemberOptions,
    extraPivotParamsConfig: state.subheader.extraPivotParamsConfig,
    extraPivotParamsOptions: state.subheader.extraPivotParamsOptions,
    extraPivotParamsSelections: state.subheader.extraPivotParamsSelections,
    ...ownProps,
  };
}

export function dispatchToProps(dispatch: AppThunkDispatch, ownProps: SubheaderOwnProps): SubheaderDispatchProps {
  const client = container.tenantConfigClient;
  const { sortByRequired } = ownProps;
  return {
    getViewDefns(defns: string[]) {
      dispatch(requestViewDefns(defns));
      client
        .getTenantViewDefns({
          defnIds: defns,
          appName: ASSORTMENT,
        })
        .then((resp) => {
          dispatch(
            receiveViewDefns({
              groupBy: resp[0],
              sortBy: resp[1],
              countLimit: resp[2],
              pareDown: resp[3],
            })
          );
        })
        .catch((error) => {
          if (sortByRequired) {
            dispatch(
              receiveStyleEditError({
                type: ComponentErrorType.config,
                message: (error as Error)?.message,
                name: 'Subheader',
                issues: error,
              })
            );
          } else {
            // Let the user know if a viewDefn is missing
            toast.error('One or more of the Subheader view definitions are missing from this configuration.');
            dispatch(receiveError("Couldn't load view definitions"));
          }
        });
    },
    updateSearchString(value: string) {
      dispatch(updateSearch(value));
    },
    updateAltSearchString(value: string) {
      dispatch(updateAlternateSearch(value));
    },
    updateFlowStatus(values: number[]) {
      dispatch(updateFlowStatus(values));
    },
    updateAltFlowStatus(values: number[]) {
      dispatch(updateAlternateFlowStatus(values));
    },
    updateLookBackPeriod(value: string) {
      dispatch(updateLookBackPeriod(value));
    },
    setGroupBySelection(value: number | null) {
      dispatch(updateGroupBy(value));
    },
    setSortBySelection(value: number | null) {
      dispatch(updateSortBy(value));
    },
    setPareDownSelections(values: TenantConfigViewItem[]) {
      dispatch(updatePareDown(values));
    },
    setSortByDirection() {
      dispatch(updateSortByDirection());
    },
    setCountLimit(value: number) {
      dispatch(updateCountLimit(value));
    },
    setFavoritesList(list: FavoriteResponseItem[]) {
      dispatch(updateFavoritesList(list));
    },
    getFavoritesList(defnId: string) {
      getFavoriteByDefnId(defnId).then((resp) => {
        if (resp) dispatch(updateFavoritesList(resp.data.data as FavoriteResponseItem[]));
      });
    },
    getFavorites(defnId: string, validVersion: number) {
      getAssortmentFavoriteByDefnId(defnId).then((resp) => {
        if (resp) {
          const validatedFavorites = validateFavorites(resp, validVersion);
          dispatch(updateFavorites(validatedFavorites));
        }
      });
    },
    setFavorites(favorites: ValidatedFavoriteResponse[]) {
      dispatch(updateFavorites(favorites));
    },
    setExtraPivotParamsOptions(options: Record<string, Option[]>) {
      dispatch(setExtraPivotParamsOptions(options));
    },
    setExtraPivotParamsSelection: (payload: { key: string; value: string | number | null }) => {
      dispatch(setExtraPivotParamsSelection(payload));
    },
    onOpenUndoPanel() {
      dispatch(setRightContainerPayload({ type: RightContainerPayloadType.Undo }));
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    resetGridModel() {
      dispatch(resetGridModel());
    },
  };
}
const sensitiveView = flow(() => Subheader, makeBreadcrumbSensitive)();
export default connect(mapStateToProps, dispatchToProps)(sensitiveView);
