import { isNil } from 'lodash';
import { AssortmentScopeSelectorSlice } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { isWorklistActive } from 'src/services/configuration/codecs/confdefnComponents';
import { AppState } from 'src/store';

export function inputIsNotNullOrUndefined<T>(input: null | undefined | T | Array<null | undefined | T>): input is T {
  if (Array.isArray(input)) {
    return input.every(inputIsNotNullOrUndefined);
  }
  return input !== null && input !== undefined;
}

export function topMemInWorklistSelected(state: AppState) { 
  return !isWorklistActive(state) || (isWorklistActive(state) && !isNil(state.worklist.selectedItemId));
}

export function isScopeDefined(scope: AssortmentScopeSelectorSlice) {
  return scope.scopeSelected;
}

export function isSubheaderLoaded(subheader: SubheaderSlice) {
  return !subheader.subheaderLoading;
}
