import React, { Component } from 'react';
import { isEmpty, isEqual, isNil } from 'lodash';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import styleEditStyles from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.styles';
import { generateStylePreview } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import Subheader from 'src/components/Subheader/Subheader.container';
import { formatSummaries } from 'src/utils/Pivot/RollUp';
import CompanionList from 'src/components/CompanionList/CompanionList';
import type { StyleEditProps, StyleEditState } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import StylePreview from 'src/components/StylePreview/StylePreview';
import { FabType } from 'src/components/higherOrder/withFab';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';
import { parseCompanionListViewConfig } from 'src/utils/Component/ListView';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { companionDataParse } from 'src/components/ListGridPair/ListGridPair.utils';

export default class StyleEdit extends Component<StyleEditProps, StyleEditState> {
  constructor(props: StyleEditProps) {
    super(props);

    this.state = {
      companionScrollTo: this.props.redirectSelection && {
        eventId: Date.now(),
        where: {
          key: 'id',
          value: this.props.redirectSelection,
        },
      },
      selectedCompanionId: null,
      sortByDirection: 'desc',
      sortByDataIndex: '',
    };
  }

  componentDidMount() {
    this.props.onShowView();
    this.setupDefaultSortBy();
  }

  componentDidUpdate(prevProps: StyleEditProps) {
    if (!prevProps.redirectSelection && this.props.redirectSelection) {
      const { companionData } = this.generateCompanionData();
      const selectedCompanionItem = companionData.find(s => s.id === this.props.redirectSelection?.id);
      this.setState({
        selectedCompanionId: selectedCompanionItem?.id ?? null,
        companionScrollTo: {
          eventId: Date.now(),
          where: {
            key: 'id',
            value: this.props.redirectSelection.id,
          },
        },
      });
    }

    if (!isEqual(this.props.config, prevProps.config)) {
      this.setupDefaultSortBy();
    }
  }

  componentWillUnmount(): void {
    this.props.onDestroy();
  }

  setupDefaultSortBy() {
    if (isEmpty(this.state.sortByDataIndex)) {
      this.setState({
        sortByDataIndex: this.props.config.companionView?.sortBy.defaults.dataIndex
      });
    }
  }

  generateCompanionData = () => {
    const { config, styles } = this.props;
    const { sortByDataIndex, sortByDirection } = this.state;
    const companionDataLookup = parseCompanionListViewConfig((config.companionView.itemMappings as unknown) as TenantConfigViewData);
    const companionData = companionDataParse(styles, companionDataLookup, sortByDirection, sortByDataIndex);
    return {
      companionData,
      companionDataLookup
    }
  }

  onFabClick = () => {
    switch (this.props.fab.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  handleItemSelect = (companionItemId: string) => {
    if (isEqual(companionItemId, this.state.selectedCompanionId)) {
      this.setState(
        {
          selectedCompanionId: null,
        },
        () => {
          this.setState({
            selectedCompanionId: companionItemId,
            companionScrollTo: {
              eventId: Date.now(),
              where: {
                key: 'id',
                value: companionItemId,
              },
            },
          });
        }
      );
    } else {
      this.setState({
        selectedCompanionId: companionItemId,
        companionScrollTo: {
          eventId: Date.now(),
          where: {
            key: 'id',
            value: companionItemId,
          },
        },
      });
    }
  };

  handleSortChange = (sort: string) => {
    this.setState({
      sortByDataIndex: sort,
    });
  }
  
  handleSortDirectionChange = (direction: SortByDirection) => {
    this.setState({
      sortByDirection: direction,
    });
  };

  render() {
    // config is required to render the rest of the component chain
    // so render only overlay until config is ready
    if (isEmpty(this.props.config) && this.props.isLoading) {
      return <Overlay type="loading" visible={true} qaKey="StyleEditOverlay" />;
    }

    const {
      title,
      isLoading,
      isPrintMode,
      config,
      adornments,
      summary,
      companionFilters,
      previewFilters,
      flowStatusOptions,
      searchKeys,
      sortByDefn,
      onSearchChange,
      onFilterChange,
      onShowView,
      onUpdate,
      onError
    } = this.props;
    const { companionScrollTo, selectedCompanionId, sortByDataIndex, sortByDirection } = this.state;   

    const { companionData, companionDataLookup } = this.generateCompanionData();
    const selectedCompanionItem = companionData.find((item) => item.id === selectedCompanionId) ?? null;
    const stylePreviewData = generateStylePreview(selectedCompanionItem);
    const subheaderProps = {
      title,
      sortByDefn: !isNil(sortByDefn) ? sortByDefn : 'AssortmentBuildStyleEditSortBy',
      summary: formatSummaries(summary),
      showAlternateFlowStatus: true,
      showAlternateSearch: true,
      sortByRequired: true
    };

    return (
      <div className={styleEditStyles.buildStyleEditContainer(isPrintMode)}>
        <Overlay type="loading" visible={isLoading} />
        <Subheader {...subheaderProps} />
        <div className="data-container">
          <CompanionList
            config={config.companionView}
            selectedItemId={selectedCompanionId}
            data={companionData}
            dataLookup={companionDataLookup}
            isDataloaded={!isLoading}
            renderSearchComponent={true}
            renderFilterComponent={true}
            sortDirection={sortByDirection}
            sortField={sortByDataIndex}
            search={companionFilters.search}
            flowStatus={companionFilters.flowStatus}
            flowStatusOptions={flowStatusOptions}
            onSearchChange={onSearchChange}
            onFilterChange={onFilterChange}
            onSortChange={this.handleSortChange}
            onSortDirectionChange={this.handleSortDirectionChange}
            onItemSelect={this.handleItemSelect}
            scrollTo={companionScrollTo}
          />
          <div style={{ height: '100%', overflowX: 'auto', flexGrow: 1 }} id={"StyleEditStylePreviewScroller"} data-qa="StyleEditStylePreviewScroller">
            <StylePreview
              adornments={adornments}
              filters={previewFilters}
              searchKeys={searchKeys}
              previewData={stylePreviewData}
              renderSections={true}
              sectionsViewDefns={config.sectionsViewDefns}
              onRefreshView={onShowView}
              onStyleItemEdit={onUpdate}
              onError={onError}
            />
          </div>
        </div>
      </div>
    );
  }
}
