import { ofType } from 'redux-observable';
import { filter, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppEpic } from 'src/store';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/epicsFunctions';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import {
  clearExtraPivotParamsOptions,
  fetchExtraPivotParamsOptions,
  setExtraPivotParamsConfig,
  setPossibleBreadcrumbs,
} from './Subheader.slice';
import { get } from 'lodash';
import { maybeGetSubheaderComponentProps } from 'src/services/configuration/codecs/confdefnComponents';
import { receiveWorklistConfig } from 'src/pages/Worklist/Worklist.slice';

export const setBreadcrumbs: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type),
    map(() => {
      const breadcrumbs: string | null = get(
        state$,
        'value.perspective.activePageConf.componentProps.defns.subheader.breadcrumbs',
        null
      );
      return breadcrumbs;
    }),
    // @ts-ignore
    filter(inputIsNotNullOrUndefined),
    mergeMap((possibleBreadcrumbs: string[]) => {
      return of(setPossibleBreadcrumbs(possibleBreadcrumbs));
    })
  );
};

export const clearActiveBreadcrumb: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type),
    map(() => {
      const breadcrumbs: string | null = get(
        state$,
        'value.perspective.activePageConf.componentProps.defns.subheader.breadcrumbs',
        null
      );
      return breadcrumbs;
    }),
    // @ts-ignore
    filter(inputIsNotNullOrUndefined),
    mergeMap((possibleBreadcrumbs: string[]) => {
      return of(setPossibleBreadcrumbs(possibleBreadcrumbs));
    })
  );
};

/** Used to clear out options between page loads */
export const clearExtraPivotParamsOptionsEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type, setActiveSubPage.type),
    filter(inputIsNotNullOrUndefined),
    mergeMap(() => {
      return of(clearExtraPivotParamsOptions());
    })
  );
};

export const setExtraPivotParamsConfigEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type, setActiveSubPage.type, receiveWorklistConfig.type),
    map(() => maybeGetSubheaderComponentProps(state$.value)),
    filter(inputIsNotNullOrUndefined),
    mergeMap((subheaderDefns) => {
      const extraPivotParams = subheaderDefns.extraPivotParams ?? {};
      return of(setExtraPivotParamsConfig(extraPivotParams), fetchExtraPivotParamsOptions());
    })
  );
};
