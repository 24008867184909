import * as React from 'react';
import * as Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
// do not remove this HighMap import
import HighchartsMap from 'highcharts/modules/map';
HighchartsMap(Highcharts);
import './proj4';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import * as fp from 'lodash';
import ServiceContainer from 'src/ServiceContainer';
import { toast } from 'react-toastify';
import { GeoTrendsMapDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { isEmpty } from 'lodash';
const MARGIN_BOTTOM = 50;
const MIN_RANGE = 1000; // how far in you can zoom

export interface GeoChartProps {
  title: string;
  mapUri?: string; // allow a first blank render with title only to improve perceived performance
  className?: string;
  onMapBubbleClick?: (evt: Highcharts.SeriesClickEventObject) => void;
  mapSeries: (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[];
  geoConfig?: Highcharts.Options;
  zoomBy?: GeoTrendsMapDefn['map']['zoomBy'];
}

export interface GeoChartState {
  backgroundLoaded: boolean;
  zoomLevel: number;
  coordinates: number[];
}

const baseConfig: Highcharts.Options = {
  chart: {
    backgroundColor: '#FDFCFD',
    animation: false,
    marginBottom: MARGIN_BOTTOM,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<span>{series.name}</span><br/>',
  },
  mapNavigation: {
    enabled: true,
    buttonOptions: {
      verticalAlign: 'bottom',
    },
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    floating: true,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    symbolRadius: 7,
    symbolHeight: 14,
  },
  xAxis: {
    minRange: MIN_RANGE,
  },
  yAxis: {
    minRange: MIN_RANGE,
  },
};
interface withMapView extends Highcharts.Chart {
  mapView?: Highcharts.MapView;
}
export type HighchartsReactRef = { chart: withMapView; container: React.RefObject<HTMLDivElement> };

export default class GeoChart extends React.Component<GeoChartProps, GeoChartState> {
  private mapBackground?: any; // loaded from unchecked remote json
  chartRef = React.createRef<HighchartsReactRef>();

  constructor(props: GeoChartProps) {
    super(props);
    this.state = {
      backgroundLoaded: false,
      zoomLevel: -1, //default to -1, it makes the map not zoom too close
      coordinates: [],
    };
    this.handleMapBubbleClick = this.handleMapBubbleClick.bind(this);
  }

  updateChartData() {
    if (this.props.mapUri && !this.mapBackground) {
      this.getMapBackground(this.props.mapUri);
    }
    if (this.chartRef.current?.chart?.container) {
      const chartContainer = this.chartRef.current.chart.container;
      chartContainer.addEventListener('wheel', this.handleWheelZoom, { passive: true });
      chartContainer.addEventListener('mousedown', this.handleMousedown);
      if (this.props.zoomBy && isEmpty(this.state.coordinates)) {
        const { howMuch, coords } = this.props.zoomBy;
        const mapView = this.chartRef.current.chart.mapView;
        if (mapView) {
          const convertLatLonToPoint = coords && this.chartRef.current.chart.fromLatLonToPoint(coords);
          mapView.zoomBy(howMuch, convertLatLonToPoint && [convertLatLonToPoint.x, convertLatLonToPoint.y || 0]);
        }
      } else if (!isEmpty(this.state.coordinates)) {
        this.chartRef.current.chart.mapView?.setView(this.state.coordinates, this.state.zoomLevel);
      }
    }
  }
  handleMousedown = () => {
    document.addEventListener('mouseup', this.handleMouseUp);
    const chart = this.chartRef.current?.chart;
    if (chart && chart.mapView) {
      this.setState({
        coordinates: chart.mapView.center,
        zoomLevel: chart.mapView.zoom,
      });
    }
  };
  handleMouseUp = () => {
    const chart = this.chartRef.current?.chart;
    if (chart && chart.mapView) {
      this.setState({
        coordinates: chart.mapView.center,
        zoomLevel: chart.mapView.zoom,
      });
    }
    document.removeEventListener('mouseup', this.handleMouseUp);
  };
  handleWheelZoom = () => {
    const chart = this.chartRef.current?.chart;
    if (chart && chart.mapView) {
      const currentZoom = chart.mapView.zoom;
      this.setState({ zoomLevel: currentZoom, coordinates: chart.mapView.center });
    }
  };
  componentWillUnmount(): void {
    // Clean up event listeners
    const chartContainer = this.chartRef.current?.chart.container;
    chartContainer?.removeEventListener('wheel', this.handleWheelZoom);
    chartContainer?.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('mouseup', this.handleMouseUp); // in case it's still attached
  }
  componentDidMount() {
    this.updateChartData();
  }
  componentDidUpdate() {
    this.updateChartData();
  }

  getMapBackground(mapUri: string) {
    // data is loaded externally from /public/maps
    let mapDataPromise: Promise<Response>;

    if (mapUri) {
      mapDataPromise = fetch(mapUri);
      mapDataPromise
        .then((response) => {
          response
            .json()
            .then((parsedJson) => {
              this.mapBackground = parsedJson;
              this.setState({ backgroundLoaded: true });
            })
            .catch(() => {
              toast.error('Something went wrong loading the background map.');
              ServiceContainer.loggingService.error('Something went wrong loading the background map.');
            });
        })
        .catch(() => {
          toast.error('Something went wrong loading the background map.');
          ServiceContainer.loggingService.error('Something went wrong loading the background map.');
        });
    }
  }

  shouldComponentUpdate(nextProps: GeoChartProps, nextState: GeoChartState) {
    if (this.props.title !== nextProps.title) {
      return true;
    }
    if (this.props.mapUri !== nextProps.mapUri) {
      return true;
    }
    if (this.state.backgroundLoaded !== nextState.backgroundLoaded) {
      return true;
    }

    const oldData = this.props.mapSeries;
    const newData = nextProps.mapSeries;

    // This kind of check isn't recommended by react for blocking render reasons,
    // but I checked the perf and the equality check only takes 0.6ms so I'm leaving it
    return !fp.isEqual(oldData, newData);
  }

  handleMapBubbleClick(event: Highcharts.SeriesClickEventObject) {
    if (this.props.onMapBubbleClick) {
      this.props.onMapBubbleClick(event);
    }
  }

  render() {
    const { mapSeries, title, geoConfig } = this.props;
    const backGroundData = this.mapBackground;
    const backgroundSeries: Highcharts.SeriesOptionsType = {
      showInLegend: false,
      type: 'map',
      mapData: backGroundData,
      index: 0,
    };

    const attachFinalOptions: Highcharts.Options = {
      title: {
        text: title,
      },
      series: [backgroundSeries, ...mapSeries],
      plotOptions: {
        series: {
          states: {
            inactive: {
              enabled: false,
            },
          },
          events: {
            click: this.handleMapBubbleClick,
          },
        },
      },
    };

    // attach overall options (title, data, etc.), then apply any props.geoConfig, then apply default config
    const mapOptions: Highcharts.Options = fp.defaultsDeep(attachFinalOptions, geoConfig, baseConfig);
    return (
      <AutoSizer style={{ height: '100%', width: '100%' }}>
        {({ height, width }) => {
          if (height <= 0) {
            return null;
          }
          const sizingConf = {
            chart: { height: height - 1, width: width - 1 },
          };
          return (
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={'mapChart'}
              key={'mapChart'}
              ref={this.chartRef}
              options={fp.defaultsDeep(mapOptions, sizingConf)}
              immutable={true}
            />
          );
        }}
      </AutoSizer>
    );
  }
}
