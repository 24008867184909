/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useCallback } from 'react';
import CompanionList from 'src/components/CompanionList/CompanionList';
import { ImageLoader, Overlay } from 'src/common-ui';
import { planogramSimpleContainer, planogramButton, planogramHeader } from './PlanogramSimple.styles';
import { StandardCard } from 'src/components/StandardCardView/StandardCard/StandardCard';
import planoCompanionViewConfigMock from './PlanogramSimpleCompanionViewConfigMock.json';
import { CardViewGroup, CardViewItem } from 'src/components/StandardCardView/UIData.types';
import { concat, findIndex, isNil, without } from 'lodash';
import { ID, ASSORTMENT_BUILD, HINDSIGHTING, ASSORTMENT } from 'src/utils/Domain/Constants';
import Subheader from 'src/components/Subheader/Subheader.container';
import { BasicPivotItem, Pivot } from 'src/worker/pivotWorker.types';
import type { Size } from 'react-virtualized';
import { Grid } from 'react-virtualized/dist/es/Grid';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';
import { processSummaries } from 'src/utils/Pivot/RollUp';
import { styles } from 'src/components/StandardCardView/StandardCardView.styles';
import { classes } from 'typestyle';
import { resolvePath } from 'src/cdn';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import ColumnGroupedView from 'src/components/StandardCardView/ColumnGroupedView';
import { curry } from 'lodash/fp';
import { AssortmentPayload } from 'src/components/RightContainer/RightContainer.slice';
import { DispatchProps, StateProps } from './PlanogramSimple.container';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import { updateRangingParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import ServiceContainer from 'src/ServiceContainer';
import { FloorsetAttrResponse, MemberItem } from 'src/types/Scope';
import container from 'src/ServiceContainer';
import { processData } from './PlanogramSimple.utils';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { noop } from 'lodash';
import { getSectionContext } from 'src/utils/Domain/Perspective';
import { parseCompanionListViewConfig } from 'src/utils/Component/ListView';
import { companionDataParse } from 'src/components/ListGridPair/ListGridPair.utils';

// image loader stuff
const noImage = resolvePath(noImagePath);
const loader = new ImageLoader(() => undefined, noImage);

function parseFloorsetDropdownData(obj: MemberItem[]) {
  return obj.map((floorset) => {
    return {
      dataIndex: floorset.id,
      text: floorset.name,
      name: floorset.name,
      id: floorset.id,
    };
  });
}
type AsstPropInfoType = {
  field: string;
  value: string;
};
function mapPlanoData(choices: BasicPivotItem[], asstPropInfo: AsstPropInfoType) {
  return choices.map((choice) => {
    return {
      id: choice.id,
      [asstPropInfo.field]: choice[asstPropInfo.field] as string[],
    };
  });
}

const renderCard = (styleItem: CardViewItem, clickCallback: any, adornments: AdornmentType[]) => {
  const options = {
    showStylePane: false,
    idProp: ID,
    descProp: 'description',
    currentTab: 'assortment-strategy',
  };
  const { idProp, descProp } = options;
  const renderedImg = loader.getImageSrc(styleItem.imgSrc);
  const name = descProp === 'description' ? styleItem.name : styleItem.styleName;
  const currentContext = getSectionContext();

  // Hindsighting TY vs LY, both columns use Hindsighting Style Pane
  // Assortment Build TY vs LY, TY uses Assortment Build Style Pane, LY uses Hindsighting
  const stylePaneKey =
    styleItem.key === 'LY' || styleItem.key === 'LAST YEAR' || IS_HINDSIGHTING(currentContext)
      ? HINDSIGHTING
      : ASSORTMENT_BUILD;

  return (
    <StandardCard
      key={styleItem[idProp]}
      id={styleItem[idProp]}
      name={name}
      styleName={styleItem.styleName}
      styleId={styleItem.styleId}
      stars={styleItem.stars}
      value={styleItem.value}
      valueRenderer={styleItem.valueRenderer}
      description={styleItem[descProp]}
      styleDescription={styleItem.description}
      imgSrc={renderedImg}
      columns={styleItem.columns}
      stylePaneKey={stylePaneKey}
      onItemClick={clickCallback}
      departmentId={styleItem.departmentId}
      swatchIds={styleItem.swatchIds}
      adornments={adornments}
    />
  );
};

const verticalGroupRender = ({
  groups,
  cardWidth,
  cardHeight,
  onItemClickCallback,
  rollupConfigs,
  adornments,
}: {
  groups: CardViewGroup[];
  cardWidth: number;
  cardHeight: number;
  onItemClickCallback: (item: AssortmentPayload, eventTarget?: HTMLElement | undefined) => void;
  rollupConfigs: any;
  adornments: AdornmentType[];
}) => {
  const SCROLLBARWIDTH = 21;
  const minimumWidth = groups.length * cardWidth + SCROLLBARWIDTH;
  // TODO: this is wrong, data is incorrect and probably needs to just be send in
  const summary = processSummaries((groups[0].items as unknown) as BasicPivotItem[], rollupConfigs.view);

  // so much wrong here
  if (groups[0].groupCalcs && groups[0].groupCalcs[0].title === 'Count') {
    groups[0].groupCalcs[0].raw = groups[0].items.length;
    groups[0].groupCalcs[0].value = (groups[0].items.length.toString() as unknown) as number;
  }
  const numCardsPerColRow = (totalWidth: number) => Math.floor(totalWidth / groups.length / cardWidth);

  const cellRenderer = (
    rowList: CardViewItem[][],
    numCardsPerCol: number,
    {
      columnIndex,
      rowIndex,
      style,
    }: {
      columnIndex: number;
      rowIndex: number;
      style: React.CSSProperties;
    }
  ) => {
    let className = styles.colCard;
    if ((columnIndex + 1) % numCardsPerCol === 0 && columnIndex !== numCardsPerCol * groups.length - 1) {
      className = classes(className, styles.colRightCard);
    }
    const keyValue = `${rowIndex}_${columnIndex}`;

    const cardStyle = {
      ...style,
    };

    return (
      <div key={keyValue} style={cardStyle} className={className}>
        {rowList[rowIndex][columnIndex]
          ? renderCard(rowList[rowIndex][columnIndex], onItemClickCallback, adornments)
          : undefined}
      </div>
    );
  };

  const autoSizerContent = (size: Size) => {
    const { width, height } = size;
    if (width === 0 || height === 0) {
      return <div>Something went wrong</div>;
    }
    const cardsPerColRow = numCardsPerColRow(width);
    const cellRendererWithRows = curry(cellRenderer)(
      ColumnGroupedView.getRowList(cardsPerColRow, groups),
      cardsPerColRow
    );
    const columnCount = groups.length * cardsPerColRow;
    const rowCount = Math.max(...groups.map((group) => Math.ceil(group.items.length / cardsPerColRow)));
    const TOTAL_GRID_WIIDTH = cardWidth * columnCount;
    const GRID_AND_SCROLLBAR_WIDTH = TOTAL_GRID_WIIDTH + SCROLLBARWIDTH;
    const limitHeight = height - SUBHEADER_HEIGHT;

    return (
      <React.Fragment>
        <article
          className={styles.columnGrouping}
          style={{
            width: TOTAL_GRID_WIIDTH,
            marginLeft: (width - GRID_AND_SCROLLBAR_WIDTH) / 2,
          }}
        >
          <header className={styles.groupsHeader}>{ColumnGroupedView.buildGroupheaders(groups, summary)}</header>
          <Grid
            cellRenderer={({ columnIndex, rowIndex, style }) =>
              cellRendererWithRows({
                columnIndex,
                rowIndex,
                style,
              })
            }
            columnCount={columnCount}
            columnWidth={cardWidth}
            height={limitHeight}
            rowCount={rowCount}
            rowHeight={cardHeight + 15}
            width={GRID_AND_SCROLLBAR_WIDTH}
          />
        </article>
      </React.Fragment>
    );
  };

  return (
    <article
      className={styles.colGroupCont}
      style={{
        // Minimum width ensures we can always fit at least *one* of each column on the screen.
        // The +1 is arbitrary, there needs to be at least *some* amount wider than the contents
        // of the autosizer to prevent a second overflow attempt.
        minWidth: minimumWidth + 1,
      }}
    >
      {/*
              TODO: Width is needed so that the groupHeaders
              do not disappear on especific rezise.
            */}
      <AutoSizer style={{ width: 1 }}>{autoSizerContent}</AutoSizer>
    </article>
  );
};
// const canvasViewItemToBasicPivotItem = (item: CardViewItem): BasicPivotItem => {
//   return ({
//     ...item,
//     image: item.imgSrc,
//     attributevaluerank: item.stars,
//   } as unknown) as BasicPivotItem;
// };

type PlanogramSimpleProps = DispatchProps &
  StateProps & {
    mostRecentChange: number;
  };
type FloorsetInfo = {
  floorsets: MemberItem[];
  selectedFloorset: FloorsetAttrResponse | null;
  selectedIndex: number;
};
type IdLocations = {
  left: string[];
  center: string[];
  right: string[];
};
const PlanogramSimple = (props: PlanogramSimpleProps) => {
  // mock data
  // const groupedStyles = props.groupedStyles;
  const [pivotData, setPivotData] = useState<BasicPivotItem[]>([]);
  const [needRefreshData, setNeedsRefreshData] = useState(true);
  const [isloading, setIsLoading] = useState(false);
  const [groupedData, setGroupedData] = useState<CardViewGroup[] | undefined>(undefined);
  const [isFloorsetLoading, setFloorsetLoading] = useState(false);
  const [leftCompanionItems, setLeftCompanionItems] = useState<BasicPivotItem[]>([]);
  const [rightCompanionItems, setRightCompanionItems] = useState<BasicPivotItem[]>([]);
  const [centerItems, setCenterItems] = useState<CardViewGroup[]>([]);
  const [floorsetInfo, setFloorsetInfo] = useState<FloorsetInfo>({
    floorsets: [],
    selectedFloorset: null,
    selectedIndex: -1,
  });
  const [lastFetched, setLastFetched] = useState<number>(-1);

  const [idLocations, setIdLocations] = useState<IdLocations>({ left: [], center: [], right: [] });

  useEffect(() => {
    props.fetchConfig();
  }, []);
  useEffect(() => {
    if (isFloorsetLoading || floorsetInfo.floorsets.length > 0) return;
    setFloorsetLoading(true);
    container.scopeClient.getFloorsets().then(async (resp) => {
      const selectedFloorset = await container.scopeClient.getFloorsetAttributes(resp[0].id);
      setFloorsetInfo({
        floorsets: resp,
        selectedFloorset: selectedFloorset,
        selectedIndex: 0,
      });
      setFloorsetLoading(false);
    });
  }, [props.loaded, isFloorsetLoading, floorsetInfo]);

  useEffect(() => {
    if (props.loaded == false) return;
    if (lastFetched > 0 && lastFetched > props.mostRecentChange) return;
    if (floorsetInfo.selectedFloorset == null) return;
    setIsLoading(true);
    const { pivotService } = container;
    const selectedFloorset = floorsetInfo.floorsets[floorsetInfo.selectedIndex].id;
    setLastFetched(Date.now());
    pivotService
      .listData(props.modelDefn, ASSORTMENT, {
        aggBy: 'level:prodrootlevel,level:stylecolor',
        topMembers: selectedFloorset,
      })
      .then((resp: Pivot) => {
        resp.tree[0].children.forEach((i) => {
          switch (i.asst_ration_type) {
            case 'new_adds':
              i.worklist_type = 'added';
              break;
            case 'keep_drop_candidates':
              i.worklist_type = 'dropped';
              break;
            default:
              i.worklist_type = '';
          }
        });
        setPivotData(resp.tree);
        const grouped = processData(resp.tree, props.viewDefn, props.subheader);
        setGroupedData(undefined);

        // the data needs to be kept locally in order to keep all the grids in sync
        // so once the props come down,
        const leftItemIds = resp.tree[0].children.filter((i) => i.asst_ration_type === 'new_adds').map((i) => i.id);
        const rightItemIds = resp.tree[0].children.filter((i) => i.asst_ration_type === 'dropped').map((i) => i.id);
        const centerIds = resp.tree[0].children
          .filter((i) => ['dropped', 'new_adds'].indexOf(i.asst_ration_type) < 0)
          .map((i) => i.id);
        // set the new data into state, so it can be manipulated locally
        setIdLocations({
          left: leftItemIds,
          center: centerIds,
          right: rightItemIds,
        });
        setGroupedData(grouped);

        // slice the top and bottom off and make into the left/rigth companion items
      })
      .catch((err: any) => container.loggingService.error('Something went wrong.', err))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loaded, props.mostRecentChange, lastFetched]);

  const generateCompanionData = () => {
    const companionDataLookup = parseCompanionListViewConfig(planoCompanionViewConfigMock.itemMappings);
    const leftData = companionDataParse(leftCompanionItems ?? [], companionDataLookup, 'desc');
    const rightData = companionDataParse(rightCompanionItems ?? [], companionDataLookup, 'desc');
    return {
      companionDataLookup,
      leftData,
      rightData,
    }
  }

  const dropItemFromAssortment = useCallback(
    (style: AssortmentPayload) => {
      const { left, center, right } = idLocations;

      const origChild = pivotData[0].children.find((choice) => choice.id === style.id);
      if (!origChild) return; //fucking how?

      if (origChild.asst_ration_type === 'new_adds') {
        const newLeft = concat(left, style.id);
        const newCenter = without(center, style.id);
        setIdLocations({
          left: newLeft,
          center: newCenter,
          right,
        });
      } else {
        const newRight = concat(right, style.id);
        const newCenter = without(center, style.id);
        setIdLocations({
          left,
          center: newCenter,
          right: newRight,
        });
      }
    },
    [idLocations, pivotData]
  );

  useEffect(() => {
    if (groupedData != null) {
      const nextLeftItems = pivotData[0].children
        .filter((item) => idLocations.left.indexOf(item.id) >= 0)
        .map((item) => {
          return {
            ...item,
            image: item.imgSrc,
            attributevaluerank: item.stars,
          };
        });
      const nextRightItems = pivotData[0].children
        .filter((item) => idLocations.right.indexOf(item.id) >= 0)
        .map((item) => {
          return {
            ...item,
            image: item.imgSrc,
            attributevaluerank: item.stars,
          };
        });
      const processedPivotData = pivotData[0].children.filter((i) => idLocations.center.indexOf(i.id) >= 0);
      const cleanResp = [
        {
          ...pivotData[0],
          children: processedPivotData,
        },
      ];
      const newResi = processData(cleanResp, props.viewDefn, props.subheader);
      setCenterItems(newResi);
      setLeftCompanionItems(nextLeftItems);
      setRightCompanionItems(nextRightItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedData, idLocations, props.subheader]);

  useEffect(() => {
    // this is used to break the memoization for the refresh data effect
    setNeedsRefreshData(false);
  }, [needRefreshData]);

  const addItemToAssortment = (leftOrRight: 'left' | 'right', styleId: string) => {
    if (leftOrRight === 'left') {
      const { left, center, right } = idLocations;
      const newLeft = without(left, styleId);
      const newCenter = concat(center, styleId);
      setIdLocations({
        left: newLeft,
        center: newCenter,
        right,
      });
      pivotData[0].children.forEach((c) => {
        if (c.id === styleId) c.worklist_type = 'added';
      });
    } else {
      const { left, center, right } = idLocations;
      const newRight = without(right, styleId);
      const newCenter = concat(center, styleId);
      setIdLocations({
        left,
        center: newCenter,
        right: newRight,
      });
    }
  };

  const approveUpdate = (centerChoices: CardViewGroup[]) => {
    const { asstPropInfo } = props;
    const choicePlanoData = mapPlanoData(pivotData[0].children, asstPropInfo);
    const addedChoices = centerChoices[0].items
      .filter((choice) => choice.worklist_type === 'added' && idLocations.center.indexOf(choice.id) >= 0)
      .map((choice) => choice.id);
    const removedChoices = idLocations.right;
    // take data and strip relevant item

    const addedChoiceData: any = choicePlanoData.filter((choice) => addedChoices.indexOf(choice.id as string) >= 0);
    const removedChoiceData: any = choicePlanoData.filter((choice) => removedChoices.indexOf(choice.id as string) >= 0);
    const { floorsets, selectedIndex } = floorsetInfo;
    setIsLoading(true);
    Promise.all(
      concat(
        addedChoiceData.map((choice: any) => {
          updateRangingParams(choice.id, {
            [floorsets[selectedIndex].id]: {
              [asstPropInfo.field]: concat(choice[asstPropInfo.field], asstPropInfo.value),
              time: floorsets[selectedIndex].id,
            },
          });
        }),
        removedChoiceData.map((choice: any) => {
          updateRangingParams(choice.id, {
            [floorsets[selectedIndex].id]: {
              [asstPropInfo.field]: without(choice[asstPropInfo.field], asstPropInfo.value),
              time: floorsets[selectedIndex].id,
            },
          });
        })
      )
    )
      .catch((err) => {
        ServiceContainer.loggingService.error('Failed to update planogram data', err);
      })
      .finally(() => props.onUpdateAssortment())
      .then(() => {
        toast.info('Planogram Approved!');
        setIsLoading(false);
        setLastFetched(1);
      });
  };
  const requiredViewDefns = props.requiredViewDefns;
  const addLeft = addItemToAssortment.bind(null, 'left');
  const addRight = addItemToAssortment.bind(null, 'right');
  const extraDropdowns = [
    {
      label: 'Floorset',
      defaultSelection: 0,
      selection: floorsetInfo.selectedIndex,
      handleChangeOnDropdown: (event: React.ChangeEvent<HTMLSelectElement>) => {
        (async () => {
          const floorsets = floorsetInfo.floorsets;
          const newValue = event.currentTarget.textContent;
          const selectedIndex = findIndex(floorsets, (option) => {
            return option.name === newValue;
          });
          const selectedFloorset = await container.scopeClient.getFloorsetAttributes(floorsets[selectedIndex].id);
          setFloorsetInfo({
            floorsets,
            selectedFloorset,
            selectedIndex,
          });
          setLastFetched(1);
        })();
      },
      options: parseFloorsetDropdownData(floorsetInfo.floorsets),
    },
  ];
  const title = props.title || 'Showing Items for POG';
  const {
    companionDataLookup,
    leftData,
    rightData,
  } = generateCompanionData();

  return (
    <React.Fragment>
      <div style={{ margin: 'auto' }}>
        <Overlay type="loading" visible={isloading} fitParent={true} />
      </div>
      <Subheader
        title={'Planogram Edit'}
        showSearch={true}
        searchReturn={() =>   noop()}
        showFlowStatus={true}
        groupByDefn={undefined}
        extraDropdowns={extraDropdowns}
        sortByDefn={requiredViewDefns[1]}
        onChangeSort={() =>   noop()}
        onChangeGroup={() =>   noop()}
        customEl={
          <Button
            color="secondary"
            variant="contained"
            className={planogramButton}
            onClick={() => {
              if (!isNil(groupedData) && !isNil(rightCompanionItems)) {
                approveUpdate(groupedData);
              }
            }}
          >
            Approve
          </Button>
        }
      />
      <div className={planogramHeader}>
        <h4>{`${title} | Set Date: ${floorsetInfo.selectedFloorset ? floorsetInfo.selectedFloorset.slsstart : ''}`}</h4>{' '}
      </div>

      <div className={planogramSimpleContainer}>
        {centerItems.length > 0 ? (
          <React.Fragment>
            <div className={'companion'}>
              <span>Add Candidates</span>
              <CompanionList
                isDataloaded={props.loaded}
                onlyUserInitiatedClicks={true}
                config={planoCompanionViewConfigMock}
                sortDirection={'desc'}
                selectedItemId={null}
                data={leftData}
                dataLookup={companionDataLookup}
                renderSearchComponent={false}
                renderFilterComponent={false}
                onItemSelect={addLeft}
                hoverActionElement={<span>Add to Assortment</span>}
              />
            </div>
            {verticalGroupRender({
              groups: centerItems || [],
              cardWidth: 254,
              cardHeight: StandardCard.calcCardHeight(),
              onItemClickCallback: dropItemFromAssortment,
              rollupConfigs: props.calcViewDefn,
              adornments: props.adornments,
            })}
            <div className={'companion'}>
              <span>Dropped</span>
              <CompanionList
                isDataloaded={props.loaded}
                onlyUserInitiatedClicks={true}
                config={planoCompanionViewConfigMock}
                sortDirection={'desc'}
                selectedItemId={null}
                data={rightData}
                dataLookup={companionDataLookup}
                renderSearchComponent={false}
                renderFilterComponent={false}
                onItemSelect={addRight}
                hoverActionElement={<span>Add to Assortment</span>}
              />
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default PlanogramSimple;
