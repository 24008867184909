import { connect } from 'react-redux';
import { isNil, flow } from 'lodash';

import ListGridPair from 'src/components/ListGridPair/ListGridPair';
import container from 'src/ServiceContainer';
import { ASSORTMENT, STYLE_ID, ID } from 'src/utils/Domain/Constants';
import { StylePaneable } from 'src/components/HistoryStylePane/HistoryStylePane.types';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { OverLoadSubheaderProps, overloadSubheader } from 'src/components/Subheader/Subheader.slice';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import {
  cleanUp,
  receiveError as receiveGridViewError,
  requestGridViewConfig,
  receiveGridViewConfig,
  receiveUpdatedGridColumns,
  refreshGridData,
} from './GridView.slice';
import { selectState } from './GridView.selectors';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import store, { AppThunkDispatch } from 'src/store';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { withFab } from 'src/components/higherOrder/withFab';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FavoriteListItemStorage, FavoriteResponseItem } from 'src/components/Subheader/Favorites/Favorites.types';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { GridViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import {
  CompanionViewDefn,
  GroupByConfig,
  RollupDefn,
  SortByConfig,
} from 'src/services/configuration/codecs/viewdefns/general';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';
import { getSectionContext } from 'src/utils/Domain/Perspective';
import { GridViewOwnProps } from 'src/services/configuration/codecs/ownProps';

export interface GridViewDispatchProps {
  onShowView(defnIds: string[]): void;
  onRefetchData(): void;
  onDestroy(): void;
  openStylePane(stylePaneable: StylePaneable): void;
  onUpdateConfig(config: TenantConfigViewData): void;
}

function retrieveGridIdentityProps(config: TenantConfigViewData & { identityProps?: IdentityPropsConfig }) {
  const { identityProps } = config;

  if (!isNil(identityProps)) {
    return identityProps;
  }

  // fallback to previously hardcoded values

  // GridView style review views are configured slightly different
  const [, , , section] = store.getState().perspective.activePage.split('/');
  const isStyleReviewSection = section === 'style-review';

  return isStyleReviewSection ? { id: STYLE_ID } : { id: ID };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: GridViewOwnProps): GridViewDispatchProps {
  const { tenantConfigClient } = container;
  const { defns } = ownProps;

  return {
    onShowView() {
      dispatch(requestGridViewConfig());
      tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [GridViewDefn, SortByConfig, GroupByConfig, RollupDefn, CompanionViewDefn],
        })
        .then((resp: (TenantConfigViewData & { identityProps?: IdentityPropsConfig })[]) => {
          const defnsLength = defns.view.length;
          const favorites: FavoriteResponseItem[] = (resp[defnsLength] as unknown) as FavoriteResponseItem[];
          const unmodifiedViewDefn = resp[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            '',
            favorites,
            dispatch,
            unmodifiedViewDefn
          );
          const grid = localConfig?.config ? localConfig.config : unmodifiedViewDefn;
          const identityPropsConfig = retrieveGridIdentityProps(resp[0]);
          const viewConfigs = {
            grid,
            listSort: resp[1],
            subheaderRollUp: resp[3],
            list: resp[4],
            unmodifiedViewDefn,
            identityPropsConfig,
          };

          dispatch(receiveGridViewConfig(viewConfigs));

          const subheaderProps: OverLoadSubheaderProps = {
            groupByOptions: resp[2],
            showSearch: true,
          };
          dispatch(overloadSubheader(subheaderProps));
        })
        .catch((error) =>
          dispatch(
            receiveGridViewError({
              type: ComponentErrorType.data,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.gridView,
              issues: error,
              defnId: error.defnId,
            })
          )
        );
    },
    onRefetchData() {
      dispatch(refreshGridData()); // Use epic instead
    },
    openStylePane(stylePaneable: StylePaneable) {
      const currentContext = getSectionContext();
      const isHindsighting = IS_HINDSIGHTING(currentContext);
      dispatch(
        setRightContainerPayload({
          type: RightContainerPayloadType.History,
          isAssortmentBuild: !isHindsighting,
          ...stylePaneable,
        })
      );
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(receiveUpdatedGridColumns(config.view));
    },
  };
}

const wrappedView = flow(() => ListGridPair, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(selectState, dispatchToProps)(wrappedView);
